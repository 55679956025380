import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZpxTableComponent } from '../zpx-table/zpx-table.component';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { PassholderTableDataSource } from './passholder-table.datasource';
import { PASSHOLDER_TYPES } from '@src/app/models/zpx-api.model';
import { GetEnvironmentService } from '@src/app/services/get-environment/get-environment.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-passholder-table',
  standalone: true,
  providers: [PassholderTableDataSource],
  imports: [CommonModule, ZpxTableComponent],
  templateUrl: './passholder-table.component.html',
  styleUrls: ['./passholder-table.component.scss']
})
export class PassholderTableComponent implements OnInit, OnDestroy {
  constructor(
    private passholderDataSource: PassholderTableDataSource,
    private getEnvService: GetEnvironmentService
  ) {}

  private onDestroy$ = new Subject<void>();
  columns$ = new BehaviorSubject(null);

  dataSource = this.passholderDataSource;
  offset = 0;

  pageSize =
    this.getEnvService.getEnvironmentProperty('paginationSettings')['pageSize'];

  ngOnInit(): void {
    this.dataSource.getPassholdersForTable({
      passholder_type_string: PASSHOLDER_TYPES.STUDENT,
      limit: this.pageSize,
      offset: this.offset
    });

    const standardColumns = this.dataSource.getStandardColumns();

    this.dataSource.customColumnSubject$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((customColumns) => {
        this.columns$.next([
          ...standardColumns,
          ...customColumns,
          this.dataSource.getEditColumn()
        ]);
      });
  }

  ngOnDestroy(): void {
    this.dataSource.onTableDestroy();
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.columns$.complete();
  }
}
