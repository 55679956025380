import { Injectable } from '@angular/core';
import { HttpService } from '@zonar-ui/core';
import { AuthHeadersService } from '../auth-headers-service/auth-headers.service';
import { GetEnvironmentService } from '../get-environment/get-environment.service';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import {
  ZpxApiPassholderParams,
  PassholdersExtendedResponseBody
} from '../../models/zpx-api.model';

@Injectable({
  providedIn: 'root'
})
export class ZpxApiService {
  constructor(
    private httpService: HttpService,
    private authHeadersService: AuthHeadersService,
    private getEnvService: GetEnvironmentService
  ) {}

  url = this.getEnvService.getEnvironmentProperty('apiBase')['url'];
  passholdersUrl = `${this.url}/passholders/extended`;

  getPassholders(
    params: ZpxApiPassholderParams
  ): Observable<PassholdersExtendedResponseBody> {
    return this.authHeadersService.getAuthHeaders().pipe(
      filter((headers) => headers !== null),
      mergeMap((headers) => {
        // TODO: temp Zonar user hack for zonar user to pass in division
        if (headers.hackyDivision) {
          params = {
            ...params,
            division_id: headers.hackyDivision
          };
        }
        delete headers.hackyDivision;

        if (headers)
          return this.httpService
            .get(
              this.passholdersUrl,
              new HttpParams({ fromObject: { ...params } }),
              new HttpHeaders(headers)
            )
            .pipe(
              map((response) => {
                return response.body as PassholdersExtendedResponseBody;
              })
            );
      })
    );
  }
}
