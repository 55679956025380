export enum PASSHOLDER_TYPES {
  STUDENT = 'student',
  AIDE = 'aide',
  DRIVER = 'driver'
}

export enum PASSHOLDER_COLUMN_HEADERS {
  CARD_NUMBER = 'CARD NUMBER',
  CARD_STATUS = 'CARD STATUS',
  LAST_NAME = 'LAST NAME',
  FIRST_NAME = 'FIRST NAME',
  UNIQUE_ID = 'UNIQUE ID',
  GROUP_NAME = 'GROUP NAME',
  CARD_COUNT = 'CARD COUNT'
}

export enum STATUSES {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive'
}

export interface Pass {
  id: string;
  company_id: string;
  division_id: string;
  passholder_id: string;
  pass_type_id: string;
  number: string;
  insert_ts: string;
  deactivate_ts: string;
  active: boolean;
  ignored: boolean;
  ignored_ts: string;
  last_updated_ts: string;
}

export interface PassForTable {
  card_number: string;
  card_status: STATUSES;
  last_name: string;
  first_name: string;
  unique_id: string;
  group_name: string;
  card_count: number;
  other_cards: Pass[];
  last_updated_ts: Date;
}

export interface ZpxApiPassholderParams {
  division_id?: string;
  passholder_type_string: PASSHOLDER_TYPES;
  limit: number;
  offset?: number;
}

export interface Group {
  active: boolean;
  company_id: string;
  division_id: string;
  gtc_zpass_location_id: number;
  id: string;
  name: string;
  last_updated_ts: string;
}

export interface Passholder {
  active: boolean;
  company_id: string;
  division_id: string;
  exsid: string;
  first_name: string;
  gtc_zpass_person_id: number;
  id: string;
  last_name: string;
  last_updated_ts: string;
  passholder_type_id: string;
  zpx_group_id: string;
}

export interface CustomTypeColumn {
  id: string;
  company_id: string;
  division_id: string;
  name: string;
  passholder_type_id: string;
  sequence: number;
  gtc_zpass_custom_type_column_id: number;
  last_updated_ts: string;
}

export interface CustomTypeValue {
  id: string;
  company_id: string;
  division_id: string;
  passholder_type_id: string;
  custom_type_column_id: string;
  last_updated_ts: string;
  value: string;
  passholder_id: string;
}

export interface PassholderExtended {
  custom_type_columns: CustomTypeColumn[];
  custom_type_values: CustomTypeValue[];
  passes: Pass[];
  passholder: Passholder;
  zpx_group: Group;
}

export interface PassholdersExtendedResponseBody {
  total_count: number;
  count: number;
  offset: number;
  data: PassholderExtended[];
}
