<div
  ngClass.gt-sm="app-container-desktop"
  ngClass.lt-md="app-container-mobile"
  fxLayout="column"
  fxFill>
  <zui-sidenav
    [sidenavParams]="sidenavParams"
    [header]="sidenavHeader"
    (emitSelectedCompany)="switchCompany($event)">
    <div class="sidenav-container" fxFlex fxLayout="column">
      <zui-sidenav-header
        class="header-bar-sidenav"
        (menuToggled)="onSideNavMobileMenuButtonToggled($event)">
      </zui-sidenav-header>
      <div class="content-container" id="zpass-container" fxFlex>
        <h2>Z Pass Management</h2>
        <mat-tab-group>
          <mat-tab label="Riders">
            <app-passholder-table></app-passholder-table>
          </mat-tab>
          <mat-tab label="Aides">
            <ng-template matTabContent> Aides Table </ng-template>
          </mat-tab>
          <mat-tab label="Drivers"
            ><ng-template matTabContent> Drivers Table </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
      <zui-footer-component
        *ngIf="device.mediaObserver.isActive('gt-sm')"></zui-footer-component>
    </div>
  </zui-sidenav>
</div>
