import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, of, throwError } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import {
  ZonarUITableDataSource,
  ZonarUITableModel,
  ZonarUITableCellType
} from '@zonar-ui/table';
import { Params } from '@angular/router';
import { ZpxApiService } from '@src/app/services/zpx-api-service/zpx-api.service';
import {
  Pass,
  PassForTable,
  PASSHOLDER_COLUMN_HEADERS,
  PassholderExtended,
  STATUSES,
  ZpxApiPassholderParams
} from '@src/app/models/zpx-api.model';
import { GetEnvironmentService } from '@src/app/services/get-environment/get-environment.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class PassholderTableDataSource implements ZonarUITableDataSource {
  constructor(
    private zpxApiService: ZpxApiService,
    private getEnvService: GetEnvironmentService
  ) {}

  private tableLoading = new BehaviorSubject<boolean>(false);
  private errorMessage = new BehaviorSubject<string>('');
  private totalResults = new BehaviorSubject<number>(0);
  private tableDataSubject$ = new BehaviorSubject<any[]>([]);
  private paginationParamsSubject = new BehaviorSubject<any>({});
  customColumnSubject$ = new BehaviorSubject<ZonarUITableModel[]>([]);
  paginationParams$ = this.paginationParamsSubject.asObservable();
  loading$ = this.tableLoading.asObservable();
  total$ = this.totalResults.asObservable();
  errorMsg$ = this.errorMessage.asObservable();
  data: any[] = [];
  defaultPagination = true;

  private standardColumns: ZonarUITableModel[] = [
    {
      columnDef: 'checkmark',
      header: null,
      sortable: false,
      type: ZonarUITableCellType.Checkbox,
      cellType: ZonarUITableCellType.Checkbox,
      checkboxDisabled: () => false,
      cell: (p) => p
    },
    {
      columnDef: 'card_number',
      header: PASSHOLDER_COLUMN_HEADERS.CARD_NUMBER,
      sortable: true,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassForTable) => p.card_number
    },
    {
      columnDef: 'card_status',
      header: PASSHOLDER_COLUMN_HEADERS.CARD_STATUS,
      sortable: true,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassForTable) => p.card_status
    },
    {
      columnDef: 'last_name',
      header: PASSHOLDER_COLUMN_HEADERS.LAST_NAME,
      sortable: true,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassForTable) => p.last_name
    },
    {
      columnDef: 'first_name',
      header: PASSHOLDER_COLUMN_HEADERS.FIRST_NAME,
      sortable: true,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassForTable) => p.first_name
    },
    {
      columnDef: 'unique_id',
      header: PASSHOLDER_COLUMN_HEADERS.UNIQUE_ID,
      sortable: true,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassForTable) => p.unique_id
    },
    {
      columnDef: 'group_name',
      header: PASSHOLDER_COLUMN_HEADERS.GROUP_NAME,
      sortable: true,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassForTable) => p.group_name
    },
    {
      columnDef: 'card_count',
      header: PASSHOLDER_COLUMN_HEADERS.CARD_COUNT,
      sortable: true,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassForTable) => p.card_count.toString()
    }
  ];

  pageSize =
    this.getEnvService.getEnvironmentProperty('paginationSettings')['pageSize'];

  // this method will be used for pagination https://zonarsystems.atlassian.net/browse/PUP-4791
  loadData(params?: Params): void {
    this.paginationParamsSubject.next(params);
  }

  getStandardColumns(): ZonarUITableModel[] {
    return this.standardColumns;
  }

  getPassData(passholders: PassholderExtended[]): any[] {
    const passes = [];
    passholders.forEach((passholderExtended) => {
      const custom_type_columns = passholderExtended.custom_type_columns;
      const custom_type_values = passholderExtended.custom_type_values;

      passholderExtended.passes.forEach((pass: Pass) => {
        let passForTable = {
          card_number: pass.number,
          card_status: pass.active ? STATUSES.ACTIVE : STATUSES.INACTIVE,
          last_name: passholderExtended.passholder.last_name,
          first_name: passholderExtended.passholder.first_name,
          unique_id: passholderExtended.passholder.exsid,
          group_name: passholderExtended.zpx_group.name,
          card_count: passholderExtended.passes.length,
          other_cards: passholderExtended.passes,
          last_updated_ts: new Date(pass.last_updated_ts)
        };

        custom_type_columns.forEach((c) => {
          passForTable = {
            ...passForTable,
            [c.name]: custom_type_values.find(
              (v) => v.custom_type_column_id === c.id
            )?.value
          };
        });

        const rideWithMultipleCards = passes.find(
          (p) => p.unique_id === passForTable.unique_id
        );

        if (!rideWithMultipleCards) {
          passes.push(passForTable);
        }
      });
    });
    return passes;
  }

  getEditColumn(): ZonarUITableModel {
    return {
      columnDef: 'edit',
      headerIcon: 'edit',
      header: null,
      sortable: false,
      type: ZonarUITableCellType.Icon,
      cellType: ZonarUITableCellType.Icon,
      cell: () => 'edit'
    };
  }

  getCustomColumns(customTypeColumns: any[]): ZonarUITableModel[] {
    if (customTypeColumns?.length) {
      return customTypeColumns.map((c) => {
        return {
          columnDef: c.name,
          header: c.name,
          sortable: true,
          cellType: ZonarUITableCellType.Text,
          cell: (p: PassForTable) => p[c.name]
        };
      });
    }
    return [];
  }

  getPassholdersForTable(params: ZpxApiPassholderParams) {
    this.tableLoading.next(true);
    this.zpxApiService
      .getPassholders(params)
      .pipe(
        catchError((error) => {
          this.tableLoading.next(false);
          return throwError(error);
        })
      )
      .subscribe(
        (passholders) => {
          this.data = this.getPassData(passholders.data);

          const customTypeColumns = passholders.data[0]?.custom_type_columns;

          this.customColumnSubject$.next(
            this.getCustomColumns(customTypeColumns)
          );

          this.tableDataSubject$.next(this.data);

          this.totalResults.next(passholders.total_count);
          this.tableLoading.next(false);
        },
        (error) => {
          this.errorMessage.next(error);
        }
      );
  }

  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    return this.tableDataSubject$.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.tableLoading.complete();
    this.tableDataSubject$.complete();
  }

  onTableDestroy(): void {
    this.tableDataSubject$.complete();
    this.tableLoading.complete();
  }
}
